
import Kirbyimage from './kirbyimage.vue'
import KirbyLink from './kirbylink.vue'
export default {
    name: "homepagetop",
    props:{
      content: Object
    }, 
    data(){
      return {
        isHovering: false
      }
    }, 
    components:{
      KirbyLink, Kirbyimage
    }
}
